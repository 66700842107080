
import axios from 'axios';
import Analytics from 'factory/modules/Analytics';
import config from './config';
import vernacular from './LnConfig';
import { __getMobileOperatingSystem, __getSearchParmas, __localStorageDel, __getCookie, __localStorageGet, __sessionStorageDel, __removeSiteStorage } from './HK_service';
var URL = require('url-parse');
export const extractData = function (data, key) {
	let _data = [];
	if (data[key] !== undefined)
		_data.push(data[key]);
	if (data.ch !== undefined)
		_data = [..._data, ...extractData(data.ch, key)];
	if (data.length > 0)
		for (let i = 0; i < data.length; i++)
			_data = [..._data, ...extractData(data[i], key)];
	return _data;
};
// const _detectPageType = function(url) {
// 	if(url.indexOf('navKey=CP-') > -1)
// 		return 'category';
// 	if(url.indexOf('navKey=SCT-') > -1 || url.indexOf('navKey=CL-') > -1)
// 		return 'menuLanding';
// 	if(url.indexOf('navKey=BR-') > -1)
// 		return 'brandCatalog';
// 	if(url.indexOf('txtQ=') > -1)
// 		return 'search';
// 	if(url.indexOf('/flash-sale') > -1)
// 		return 'FlashSale';
// 	return false;
// }
const HK = {
	// Bind config
	config: config,
	vernacular: vernacular,
	checkMembershipProduct: function (prdctData) {
		let lotaltyProductCatName = 2;
		if (prdctData) {
			return (prdctData.sp_typ === lotaltyProductCatName || prdctData.isServiceType);
		}
		else
			return false;
	},
	// Third Part Scripts integration and ohers
	firstLoad: function (onDone, ...args) {
		console.log("%cWelcome to Gritzo", "color: #C8702B; font-size: 22px;");
		window._FL = true;
		let affiliateName = this.getSearchParmas().utm_source;
		let affiliateMedium = this.getSearchParmas().utm_medium;
		let affiliateCampaign = this.getSearchParmas().utm_campaign;
		let subid = this.getSearchParmas().subid;
		let fbclid = __getSearchParmas().fbclid || '';
		if (affiliateName || affiliateMedium || affiliateCampaign || subid) {
			HK.Cookies.delete('affiliateName');
			HK.Cookies.delete('traffic_source');
			HK.Cookies.delete('affiliateMedium');
			HK.Cookies.delete('affiliateCampaign');
			HK.Cookies.delete('subid');
			affiliateName && this.Cookies.set("affiliateName", affiliateName || '', .02, false);
			affiliateMedium && this.Cookies.set("affiliateMedium", affiliateMedium || '', .02, false);
			affiliateCampaign && this.Cookies.set("affiliateCampaign", affiliateCampaign || '', .02, false);
			subid && this.Cookies.set("subid", subid || '', .02);
			affiliateName && this.Cookies.set("traffic_source", affiliateName || '', .02);
			if (fbclid) {
				let duration=1;
				const d = new Date();
				this.Cookies.set("fbclid", fbclid, d.getDate() + duration, true);
			}
			// const oneDayCookie = ['Affly', 'Affnet', 'Affoy', 'cashkaro', 'ClickOnik', 'couponzguru', 'Cuelinks', 'desidime', 'Flickstree', 'grabon', 'Icubeswire', 'indoleads', 'Pokkt', 'Refpaymedia', 'Xapads', 'Zoutons', 'Commissionads'];
			// const fiveDayCookie = ['fillymedia'];
			const oneDayCookie = [];
			const fiveDayCookie = [];

			if (affiliateName && (oneDayCookie.indexOf(affiliateName) > -1 || fiveDayCookie.indexOf(affiliateName) > -1)) {
				let duration = 1;
				if (fiveDayCookie.indexOf(affiliateName) > -1) {
					duration = 5;
				}
				const d = new Date();
				affiliateName && this.Cookies.set("affiliateName", affiliateName, d.getDate() + duration, true);
				affiliateMedium && this.Cookies.set("affiliateMedium", affiliateMedium, d.getDate() + duration, true);
				affiliateCampaign && this.Cookies.set("affiliateCampaign", affiliateCampaign, d.getDate() + duration, true);
				subid && this.Cookies.set("subid", subid, d.getDate() + duration, true);
				affiliateName && this.Cookies.set("traffic_source", affiliateName, d.getDate() + duration, true);
			} else {
				affiliateName && this.Cookies.set("affiliateName", affiliateName || '', .02, false);
				affiliateMedium && this.Cookies.set("affiliateMedium", affiliateMedium || '', .02, false);
				affiliateCampaign && this.Cookies.set("affiliateCampaign", affiliateCampaign || '', .02, false);
				subid && this.Cookies.set("subid", subid || '', .02);
				affiliateName && this.Cookies.set("traffic_source", affiliateName || '', .02);
			}
		}
		const dtalayer = {
			'affiliateName': HK.Cookies.get("affiliateName") || '',
			'traffic_source': HK.Cookies.get("traffic_source") || '',
			'affiliateMedium': HK.Cookies.get("affiliateMedium") || '',
			'affiliateCampaign': HK.Cookies.get("affiliateCampaign") || '',
			'subid': HK.Cookies.get("subid") || '',
			'fbclid': HK.Cookies.get("fbclid") || '',
		};
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(dtalayer);

		if (this.Cookies.get('origId')) {
			localStorage.setItem('isUserLoggedIn', this.Cookies.get('origId'));
			localStorage.setItem('_isUserLoggedIn', this.Cookies.get('origId'));
			HK.setCookieLoggedIn(this.Cookies.get('origId'));
		}
		this._setInitStorageItems();
		(async () => {
			let _headEle = document.getElementsByTagName('meta')[0];
			var _gtmJs;
			if (process.env.NEXT_PUBLIC_HOST_ENV === 'production' || process.env.NEXT_PUBLIC_FORCE_THIRD_PARTY_SCRIPTS) {
				// GTM
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js',
					newSession: 'true'
				});
				_gtmJs = document.createElement('script');
				_gtmJs.async = true;
				_gtmJs.id = 'gtm-sdk';
				_gtmJs.src = 'https://www.googletagmanager.com/gtm.js?id=' + config.const.GTM_CONT_ID;
				_headEle.parentNode.insertBefore(_gtmJs, _headEle);
			}
			if (onDone) {
				onDone();
			}
		})();
	},
	_setDataLayer: function (data, /*e */) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(data);
	},
	updateGaEvent: function (eventName, data, pageType) {
		let eventData = {};
		if (eventName) {
			eventData.event = eventName;
		}
		if (pageType) {
			eventData.pageType = pageType;
		}
		if (data) {
			eventData = { ...eventData, ...data };
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(eventData);
	},
	routerInit: function (pageType, navKey, __URL) {
		let $this = this;
		let neteffectiveType = false;
		let networkdownlink = false;
		window.pageType = pageType;
		HK.deepLinkMeta("", "", true);
		if (localStorage.getItem('prevScreenName') === null) {
			localStorage.setItem('currScreenName', window.pageType);
			localStorage.setItem('prevScreenName', window.pageType);
		}
		if (window.pageType !== localStorage.getItem('currScreenName')) {
			let prevPage = localStorage.getItem('currScreenName');
			localStorage.setItem('prevScreenName', prevPage);
			localStorage.setItem('currScreenName', window.pageType);
		}
		else {
			localStorage.setItem('prevScreenName', window.pageType);
			localStorage.setItem('currScreenName', window.pageType);
		}
		$this._dataLayer({ pageType: pageType }, pageType);
		if (navigator.connection) {
			neteffectiveType = (navigator.connection.effectiveType) ? navigator.connection.effectiveType : '';
			networkdownlink = (navigator.connection.downlink) ? navigator.connection.downlink + "Mb/s" : '';
			this.tracker('event', 'NetWorkInfo', 'effectiveType', neteffectiveType);
			this.tracker('event', 'NetWorkInfo', 'networkdownlink', networkdownlink);
		}
		if (typeof window.__factory === 'undefined' || (typeof window.__factory !== 'undefined' && typeof window.__factory.connection === 'undefined')) {
			window.__factory = {
				connection: {
					neteffectiveType: neteffectiveType,
					networkdownlink: networkdownlink
				}
			};
		} else {
			window.__factory.connection = {
				neteffectiveType: neteffectiveType,
				networkdownlink: networkdownlink
			};
		}
		return new Promise(function (resolve, reject) {
			$this._getInitData(pageType, navKey, __URL).then(function (args) {
				let _dataLayerObj = args.user;
				let isPublished = true;
				if (Object.keys(config.pageTypes.plp).indexOf(pageType) > -1) {
					let _cTags = args && args.page && (args.page.catTag !== undefined && args.page.catTag !== null && args.page.catTag.length > 0) ? args.page.catTag[0].tags : [];
					let _allCats = args && args.page && args.page.catBrowse !== undefined ? extractData(args.page.catBrowse, 'nm') : [];
					_allCats = _allCats.filter(function (elem, pos) {
						return _allCats.indexOf(elem) === pos;
					});
					let _allNavKeys = args.page.catBrowse !== undefined ? extractData(args.page.catBrowse, 'navKey') : [];
					_allNavKeys = _allNavKeys.filter(function (elem, pos) {
						return _allNavKeys.indexOf(elem) === pos;
					});
					_dataLayerObj = { ..._dataLayerObj, ...{ allCats: _allCats }, ...{ cTags: _cTags }, ...{ allNavKeys: _allNavKeys } };
					if (window.location.href.indexOf('navKey') > -1)
						_dataLayerObj = { ..._dataLayerObj, ...{ navKey: HK.getSearchParmas().navKey } };
					if (args.page.catBrowseMenu !== null && args.page.catBrowseMenu !== undefined) {
						let _primaryMenu = args.page.catBrowseMenu.nm;
						let _secondaryMenu = '';
						let _tertiaryMenu = '';
						if (args.page.catBrowseMenu.ch !== undefined && args.page.catBrowseMenu.ch !== null && args.page.catBrowseMenu.ch.length > 0) {
							_secondaryMenu = args.page.catBrowseMenu.ch[0].nm;
							if (args.page.catBrowseMenu.ch[0].ch !== undefined && args.page.catBrowseMenu.ch[0].ch !== null && args.page.catBrowseMenu.ch[0].ch.length > 0) {
								_tertiaryMenu = args.page.catBrowseMenu.ch[0].ch.filter(x => x.selected);
								_tertiaryMenu = _tertiaryMenu !== null && _tertiaryMenu !== undefined && _tertiaryMenu.length > 0 ? _tertiaryMenu[0].nm : '';
							}
						}
						_dataLayerObj = { ..._dataLayerObj, ...{ primaryMenu: _primaryMenu, secondaryMenu: _secondaryMenu, tertiaryMenu: _tertiaryMenu } };
					}
					if (args.page.pgCnclUrl !== null && args.page.pgCnclUrl !== undefined) {
						_dataLayerObj = { ..._dataLayerObj, ...{ canonicalUrl: config.AppURL + args.page.pgCnclUrl.urlFragment + '?navKey=' + args.page.pgCnclUrl.navKey } };
					}
				}
				if (pageType === 'storeVariant') {
					let pdpresult = args.data.results;
					let _cTags = pdpresult.page.catTag !== undefined && pdpresult.page.catTag !== null ? pdpresult.page.catTag.tags : [];
					let _allCats = pdpresult.page.pgBreadCrumbs !== undefined ? extractData(pdpresult.page.pgBreadCrumbs, 'nm') : [];
					_allCats.shift();
					_allCats.pop();
					let _allNavKeys = pdpresult.page.pgBreadCrumbs !== undefined ? extractData(pdpresult.page.pgBreadCrumbs, 'navKey') : [];
					_allNavKeys.shift();
					_allNavKeys.pop();
					_dataLayerObj = { ..._dataLayerObj, ...{ allCats: _allCats }, ...{ cTags: _cTags }, ...{ allNavKeys: _allNavKeys } };
					let _primaryCategory = _allCats[0];
					let _secondaryCategory = _allCats[1];
					let _tertiaryCategory = _allCats[2];
					let navKey = HK.getSearchParmas().navKey;
					if (window.location.href.indexOf('navKey') > -1)
						_dataLayerObj = { ..._dataLayerObj, ...{ navKey: HK.getSearchParmas().navKey } };
					_dataLayerObj = { ..._dataLayerObj, ...{ primaryCategory: _primaryCategory, secondaryCategory: _secondaryCategory, tertiaryCategory: _tertiaryCategory } };
					if (pdpresult.page.pgCnclUrl !== null && pdpresult.page.pgCnclUrl !== undefined) {
						_dataLayerObj = { ..._dataLayerObj, ...{ canonicalUrl: config.AppURL + '/sv' + pdpresult.page.pgCnclUrl.urlFragment } };
					}
					let data = {};
					data.variantDiscount = pdpresult.discount;
					data.variantId = pdpresult.id;
					data.variantImageM = pdpresult.images.length > 0 ? pdpresult.images[0].m_link : "";
					data.variantImageS = pdpresult.images.length > 0 ? pdpresult.images[0].xxt_link : "";
					data.variantMrp = pdpresult.mrp;
					data.variantName = pdpresult.nm;
					data.variantOfferPrice = pdpresult.offer_pr;
					data.vendorName = pdpresult.vendorName;
					data.primaryAttributeName = '';
					data.primaryAttributeValue = '';
					data.secondaryAttributeName = '';
					data.secondaryAttributeValue = '';
					data.oos = pdpresult.oos;
					data.pageType = pageType;
					for (let at = 0; at < pdpresult.attr.length; at++) {
						if (at === 0) {
							data.primaryAttributeName = pdpresult.attr[at].dis_nm;
							data.primaryAttributeValue = pdpresult.selAttr[pdpresult.attr[at].nm];
						}
						if (at === 1) {
							data.secondaryAttributeName = pdpresult.attr[at].dis_nm;
							data.secondaryAttributeValue = pdpresult.selAttr[pdpresult.attr[at].nm];
						}
					}
					data.bTags = pdpresult.brTag.tags;
					data.brand = pdpresult.brTag.brName;
					data.parentNavKey = pdpresult.catPre;
					data.productId = pdpresult.sp_id;
					data.svTags = pdpresult.varTag.tags;
					data.lpBrand = pdpresult.brTag.brName;
					data.lpPrimCat = _allCats[0];
					data.google_tag_parma_new = {
						'ecomm_prodid': navKey,
						'ecomm_totalvalue': pdpresult.offer_pr,
						'ecomm_pagetype': window.pageType,
					};
					_dataLayerObj = { ..._dataLayerObj, ...data };
				}
				if (pageType === 'pack') {
					let data = {};
					let packresult = args.data.results.packs;
					data.primaryAttributeName = '';
					data.primaryAttributeValue = '';
					data.secondaryAttributeName = '';
					data.secondaryAttributeValue = '';
					data.oos = packresult.oos;
					data.packId = packresult.id;
					data.packOfferPrice = packresult.offer_pr;
					data.packMrp = packresult.mrp;
					data.packDiscount = packresult.discount;
					data.packName = packresult.nm;
					data.packImageM = '';
					data.packImageS = packresult.images[0].xxt_link;
					data.allCats = '';
					data.allbrands = '';
					data.packVariantIds = '';
					data.packProductIds = '';
					data.bTags = '';
					data.cTags = '';
					data.pageType = pageType;
					let allbrands = [], packVariantIds = [], packProductIds = [];
					packresult.packSv && packresult.packSv.map(packitem => {
						packVariantIds.push(packitem.sv_bsc.id);
						allbrands.push(packitem.sv_bsc.brName);
						packProductIds.push(packitem.sv_bsc.sp_id);
						return '';
					});
					data.allbrands = allbrands.join(',');
					data.packVariantIds = packVariantIds.join(',');
					data.packProductIds = packProductIds.join(',');
					data.allbrands = allbrands.join(',');
					data.navKey = packresult.navKey;
					data.canonicalUrl = config.AppURL + '/pk' + packresult.urlFragment + (packresult.navKey ? "?navKey=" + packresult.navKey : "");
					_dataLayerObj = { ..._dataLayerObj, ...data };
				}
				if (window.__RI !== undefined)
					_dataLayerObj = { ..._dataLayerObj, ...{ 'event': 'reactPageView' } };
				window.__RI = new Date();
				console.log('_dataLayerObj====', _dataLayerObj);
				console.log('pageType====', pageType);
				$this._dataLayer(_dataLayerObj, pageType);

				if (pageType === "storeVariant" || pageType === "pack") {

					if (args.data.results.page.pgCnclUrl) {
						if (pageType === "storeVariant") {
							args.data.results.page.pgCnclUrl.urlFragment = '/sv' + args.data.results.page.pgCnclUrl.urlFragment;
							isPublished = args.data.results.is_published;
						}
						if (pageType === "pack") {
							args.data.results.page.pgCnclUrl.urlFragment = '/pk' + args.data.results.page.pgCnclUrl.urlFragment;
						}
					}
					let pageData;

					if (pageType === "storeVariant") {
						pageData = args && args.data && args.data.results && args.data.results.page;
					} else if (pageType === "pack") {
						pageData = args && args.data && args.data.results && args.data.results.packs && args.data.results.packs.page;
					}
					if (pageData) {
						$this._setSEO(pageData.pgSeo, pageData.pgCnclUrl, isPublished);
					}
					if (args.data.results && args.data.results.page.pgCnclUrl) {
						if (pageType === "storeVariant" && (args.data.results.pr_img !== undefined && args.data.results.pr_img !== null && (args.data.results.pr_img[$this.isMobile() ? "m_link" : "o_link"] !== null || args.data.results.pr_img[$this.isMobile() ? "m_link" : "o_link"] !== undefined))) {
							document.querySelector('meta[property="og:image"]') && document.querySelector('meta[property="og:image"]').setAttribute('content', args.data.results.pr_img[$this.isMobile() ? "m_link" : "o_link"]);
							document.querySelector('meta[name="twitter:image:src"]') && document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', args.data.results.pr_img[$this.isMobile() ? "m_link" : "o_link"]);
						}
						if (pageType === "pack" && (args.data.results.packs.image !== undefined && args.data.results.packs.image !== null && (args.data.results.packs.image[$this.isMobile() ? "m_link" : "o_link"] !== null || args.data.results.packs.image[$this.isMobile() ? "m_link" : "o_link"] !== undefined))) {
							document.querySelector('meta[property="og:image"]').setAttribute('content', args.data.results.packs.image[$this.isMobile() ? "m_link" : "o_link"]);
							document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', args.data.results.packs.image[$this.isMobile() ? "m_link" : "o_link"]);
						}
					}
					resolve(args);
				} else if (pageType === "clearance") {
					if (args?.page?.salePage?.pgSeo) {
						$this._setSEO(args.page.salePage.pgSeo, args.page.salePage.pgCnclUrl);
					}
					if (args.page.salePage.pgBanners !== undefined && args.page.salePage.pgBanners[0] !== undefined && Object.keys(args.page.salePage.pgBanners[0].pgBnrSlots).length) {
						for (let i = 0; i < Object.keys(args.page.salePage.pgBanners[0].pgBnrSlots).length; i++) {
							let currentKey = [Object.keys(args.page.salePage.pgBanners[0].pgBnrSlots)[i]];
							if (args.page.salePage.pgBanners[0].pgBnrSlots[currentKey].images.length && Object.keys(args.page.salePage.pgBanners[0].pgBnrSlots[currentKey].images[0]).length) {
								document.querySelector('meta[property="og:image"]').setAttribute('content', args.page.salePage.pgBanners[0].pgBnrSlots[currentKey].images[0][$this.isMobile() ? "m_link" : "o_link"]);
								document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', args.page.salePage.pgBanners[0].pgBnrSlots[currentKey].images[0][$this.isMobile() ? "m_link" : "o_link"]);
								break;
							}
						}
					}
					resolve(args.page);
				} else if (pageType === "storelocator") {
					if (args && args.page && args.page.page && args.page.page.page && args.page.page.page.pgSeo) {
						$this._setSEO(args.page.page.page.pgSeo, args.page.page.page.pgCnclUrl || '');
					}
					resolve(args.page);
				} else if (pageType === 'storeDetail' || pageType === 'storeList') {
					if (args && args.page && args.page.additionalDetails && args.page.additionalDetails.page && args.page.additionalDetails.page.pgSeo) {
						$this._setSEO(args.page.additionalDetails.page.pgSeo, args.page.additionalDetails.page.pgCnclUrl || '');
					}
					resolve(args.page);
				}
				else {
					if (args.page.pgSeo) {
						$this._setSEO(args.page.pgSeo, args.page.pgCnclUrl);
					}
					if (args.page.pgBanners && args.page.pgBanners[0] !== undefined && Object.keys(args.page.pgBanners[0].pgBnrSlots).length) {
						for (let i = 0; i < Object.keys(args.page.pgBanners[0].pgBnrSlots).length; i++) {
							let currentKey = [Object.keys(args.page.pgBanners[0].pgBnrSlots)[i]];
							if (args.page.pgBanners[0].pgBnrSlots[currentKey].images.length && Object.keys(args.page.pgBanners[0].pgBnrSlots[currentKey].images[0]).length) {
								document.querySelector('meta[property="og:image"]').setAttribute('content', args.page.pgBanners[0].pgBnrSlots[currentKey].images[0][$this.isMobile() ? "m_link" : "o_link"]);
								document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', args.page.pgBanners[0].pgBnrSlots[currentKey].images[0][$this.isMobile() ? "m_link" : "o_link"]);
								break;
							}
						}
					}
					resolve(args.page);
				}
			}).catch(function (e) {
				console.log(e);
				reject('Unable to load data');
			});
		});
	},
	// Initial storage items
	_setInitStorageItems: function () {
		// set logged false if not set
		if (localStorage.getItem('isUserLoggedIn') === null || !this.Cookies.get('authtoken')) {
			localStorage.setItem('isUserLoggedIn', false);
			HK.setCookieLoggedIn(false);
		}
		// set default datalyer overrider
		window._dataLayer = {};
		// Remove user auth key if expired
		if (!this.Cookies.get('authtoken')) {
			localStorage.removeItem('isUserLoggedIn');
			HK.Cookies.delete('isUserLoggedIn');
		}
	},
	// AB Test
	ABTest: {
		// control combID is always 1
		partOf: function (expID = 0, combID = 1) {
			let _cookie = HK.Cookies.get('_vis_opt_exp_' + expID + '_combi');
			return parseInt(_cookie, 10) === combID;
		},
		passGoal: function (expID = 0, goalID = 0) {
			return HK.Cookies.get('_vis_opt_exp_' + expID + '_goal_' + goalID);
		},
		trackRevenueOnOrderPage: function (campaignIdVar, goalId1Var, rev1Var, goalId2Var, rev2Var, goalId3Var, ttlItems) {
			let _vis_opt_register_conversion = window._vis_opt_register_conversion;
			try {
				window._vis_opt_queue = window._vis_opt_queue || [];
				// window._vis_opt_queue.push(function() {_vis_opt_revenue_conversion(revenue);});
				window._vis_opt_queue.push(
					function () {
						_vis_opt_register_conversion(goalId1Var, campaignIdVar, rev1Var);
					}
				);
				window._vis_opt_queue.push(
					function () {
						_vis_opt_register_conversion(goalId2Var, campaignIdVar, rev2Var);
					}
				);
				window._vis_opt_queue.push(
					function () {
						_vis_opt_register_conversion(goalId3Var, campaignIdVar, ttlItems);
					}
				);
			}
			catch (err) {
				console.log("Something went wrong");
				console.log(err);
			}
		},
		trackCustomConversionGoal: function (goalId) {
			let _vis_opt_goal_conversion = window._vis_opt_goal_conversion;
			try {
				window._vis_opt_queue = window._vis_opt_queue || [];
				window._vis_opt_queue.push(function () { _vis_opt_goal_conversion(goalId); });
			}
			catch (err) {
				console.log("Something went wrong");
				console.log(err);
			}
		}
	},
	//GA Tracker
	tracker: function (...args) {
		if (args.length < 2) return;
		if (process.env.NEXT_PUBLIC_HOST_ENV !== 'production') {
			console.log('GA Tracking: %s', JSON.stringify(args));
			return;
		}
		let _tracker = (typeof window.ga === 'function') ? ((typeof window.ga.getAll === "function") ? window.ga.getAll()[0] : false) : false;
		// If ga.js not loaded, push in dataLayer
		if (!_tracker) {
			return window.dataLayer.push({ 'event': args[1] });
		} else {
			return _tracker.send(...args);
		}
	},
	// iTracker
	itracker: function (w, p, c, e) {
		var _itracker = 'itracker=';
		if (w) {
			w = typeof w === 'string' ? w : w.join('|');
			_itracker += 'w:' + encodeURIComponent(w).replace(/%7C/g, '|') + '|;';

		}
		if (p)
			_itracker += 'p:' + encodeURIComponent(p) + '|;';

		if (c)
			_itracker += 'c:' + encodeURIComponent(c) + '|;';

		if (e)
			_itracker += 'e:' + encodeURIComponent(e) + '|;';

		return _itracker.replace(/--/g, '');
	},
	// Audio Permission 
	getUserAudioPermission: function () {
		return new Promise(function (resolve, reject) {
			// Get user audio capture permissions
			navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false
			}).then(function (e) {
				if (typeof e.active !== 'undefined')
					resolve(true);
				else
					reject(e);
			}).catch(function (e) {
				reject(e);
			});
		});
	},
	// GeoLocation Permission
	getUserLocation: function () {
		return new Promise(function (resolve, reject) {
			var _userLat, _userLng;
			// if(this._userLat && this._userLng) {
			// 	let lat = this._userLat;
			// 	let lng = this._userLng;
			// 	resolve([lat,lng,1]);
			// } else 
			//{
			if (navigator.geolocation) {
				// Ask user permission or get coords if granted
				navigator.geolocation.getCurrentPosition(function (position) {
					_userLat = position.coords.latitude;
					_userLng = position.coords.longitude;
					// Watch Position Change
					// navigator.geolocation.watchPosition(function(_position) {
					// 	this._userLat = _position.coords.latitude;
					// 	this._userLng = _position.coords.longitude;
					// }, function(e) {
					// }, {enableHighAccuracy: true, maximumAge:60000, timeout: 60000});
					let lat = _userLat;
					let lng = _userLng;
					resolve([lat, lng]);
				}, function (e) {
					reject(e);
				}, { enableHighAccuracy: true, maximumAge: 60000, timeout: 60000 });
			} else {
				reject("GeoLocation not available in Navigator");
			}
			//}
		});
	},
	// Notification Access
	getUserNotification: function () {
		return new Promise(function (resolve, reject) {
			if (typeof Notification === 'function') {
				Notification.requestPermission().then(function (result) {
					if (result === 'denied') {
						reject("Notification permission denied");
					}
					if (result === 'default') {
						reject("Notification permission dismissed");
					}
					resolve(true);
				});
			} else {
				reject("Notification not supported");
			}
		});
	},
	// CDN Image Loading
	getImage: function (path) {
		let _cdns = process.env.NEXT_PUBLIC_CDN_URLS.split(',');
		let _cdnsLength = _cdns.length;
		return _cdns[path.charCodeAt(path.length / _cdnsLength) % _cdnsLength] + '/static/media' + path;
	},
	// NUll check helper
	nullCheckHelper: function (base, key, type, defaultVal) {
		type = type || 'string';
		if (typeof base !== 'undefined' && base !== null) {
			return base[key];
		} else {
			var ret;
			switch (type) {
				case 'string':
					ret = '';
					break;
				case 'array':
					ret = [];
					break;
				case 'object':
					ret = {};
					break;
				case 'boolean':
					ret = false;
					break;
				default:
					ret = false;
					break;
			}
			return defaultVal || ret;
		}
	},
	// Get URL Params
	getSearchParmas: function (string, ssrQuery) {
		let result = {};
		if (typeof window != 'undefined') {
			string = (string || window.location.href).replace('#', '%23');
			var url = URL(string, true);
			result = url.query;
		} else {
			result = ssrQuery || {};
		}
		return result;
	},
	// remove param from url
	removeQueryParam: function (key, sourceURL) {
		var rtn = sourceURL ? sourceURL.split("?")[0] : '/',
			param,
			params_arr = [],
			queryString = (sourceURL && sourceURL.indexOf("?") !== -1) ? sourceURL && sourceURL.split("?")[1] : "";
		if (queryString !== "") {
			params_arr = queryString.split("&");
			for (var i = params_arr.length - 1; i >= 0; i -= 1) {
				param = params_arr[i].split("=")[0];
				if (param === key) {
					params_arr.splice(i, 1);
				}
			}
			rtn = rtn + "?" + params_arr.join("&");
		}
		return rtn;
	},
	// date format
	formatDate: function (datStr, formatStr) {
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];
		const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		if (formatStr === 'new') {
			const date = new Date(datStr);
			const day = date.getDate();
			const dayWithSuffix = this.numberSuffix(date.getDate());
			const weekDay = weekdays[date.getDay()];
			const month = monthNames[date.getMonth()];
			const year = date.getFullYear();
			return {
				// format 1 ex-> Mon, 22 Dec
				f1: `${weekDay}, ${day} ${month}`,

				// format 2 ex-> 5th Jan 2021
				f2: `${dayWithSuffix} ${month} ${year}`,

				// format 3 ex--> Dec 28
				f3: `${month} ${day}`,

				// format 4 ex--> Dec 17th
				f4: `${month} ${dayWithSuffix}`,
				f5: `${month} ${day}, ${year}`
			};
		}
		let d;
		if (typeof datStr === 'string') {
			datStr = datStr.split(' ')[0];
			d = new Date(datStr.replace(/-/g, "/"));
		} else {
			d = new Date(datStr);
		}
		if (formatStr === 'dd-mm-yy')
			return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
		if (formatStr === 'dd-mm,-yy')
			return d.getDate() + " " + monthNames[d.getMonth()] + ", " + d.getFullYear();
		if (formatStr === 'dd-month-yy') {
			let dateStrArr = datStr.split('-');
			datStr = dateStrArr[1] + "-" + dateStrArr[0] + "-" + dateStrArr[2];
			d = new Date(datStr.replace(/-/g, "/"));
			return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
		}
		if (formatStr === 'DD-dd-mm') {
			return weekdays[d.getDay()] + " " + d.getDate() + " " + monthNames[d.getMonth()];
		}
		if (typeof datStr === 'string') {
			d = new Date(datStr.replace(/-/g, "/"));
		} else {
			d = new Date(datStr);
		}
		return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
	},

	// number suffix
	numberSuffix: function (i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	},

	// Get Persona Tracking
	getPersona: function (prefix, suffix) {
		prefix = prefix || '';
		suffix = suffix || '';
		let _personPageTypeKey = this.config.persona.existingPageTypes[window.pageType];
		if (typeof _personPageTypeKey !== 'undefined')
			return prefix + _personPageTypeKey + suffix;
		else
			return prefix + suffix;
	},
	// is Usser Logged In
	loggedIn: function () {
		try {
			let userStatus = false;
			if (typeof window != 'undefined') {
				let _cookie = this.Cookies.get('assumeAuth') || this.Cookies.get('authtoken');
				let _localValue = this.Cookies.get('assumeId') || localStorage.getItem('isUserLoggedIn');
				userStatus = _localValue && _cookie && !localStorage.getItem('isTMPUser') ? _localValue : false;
				userStatus = userStatus !== 'false' ? userStatus : false;
				//====SSR HANDLING===========
				if (localStorage.getItem('isUserLoggedIn') && !this.Cookies.get('isUserLoggedIn')) {
					if (localStorage.getItem('isTMPUser') && !this.Cookies.get('isTMPUser')) {
						this.setCookieLoggedIn(localStorage.getItem('isUserLoggedIn', 1));
					} else {
						this.setCookieLoggedIn(localStorage.getItem('isUserLoggedIn'));
					}
				}
			}
			return userStatus;
		}
		catch (err) {
			console.log(err);
			return false;
		}

	},
	// Error Popup
	errorPopup: function (msg, arg) {
		let _errorDOM = document.getElementById('hk--error-popup');
		if (_errorDOM != null) {
			if (typeof arg === 'object' && typeof arg.title !== 'undefined')
				_errorDOM.querySelector('.hk--error-popup-title span').innerText = arg.title;
			else
				if (typeof arg === 'string') this._logError(arg);
			_errorDOM.querySelector('.hk--error-popup-body').innerText = msg;
			_errorDOM.style.display = 'block';
			_errorDOM.querySelector('.hk--error-popup-title img').onclick = function () {
				_errorDOM.style.display = 'none';
			};
		}
		if (msg.indexOf('Something went') > -1 ||
			msg.indexOf('Could not process') > -1 ||
			msg.indexOf('UNAUTHORIZED ACCESS : ADDRESS_ID') > -1 ||
			msg.indexOf('Invalid Cart') > -1 ||
			msg.indexOf('Your Order Amount has been changed') > -1) {
			Analytics.Scope().errorReport(msg);
		}
		setTimeout(() => {
			HK.closeErrorPopup();
		}, 3000);
	},

	reportErrorToDashboard: function ({ stackTrace, errorMessage }) {
		if (process.env.NEXT_PUBLIC_ENABLE_ERROR_TRACKING) {
			try {
				const body = {
					errorType: "INTERNAL SERVER ERROR POPUP",
					errorMessage: errorMessage === null
						? "The error, however, is uncertain. But you can look at the stackTrace for more help"
						: new Error(errorMessage).message + " - " + new Error(errorMessage).stack,
					errorStackTrace: stackTrace || 'Something went wrong with the stack trace!',
					userAgent: navigator.userAgent || navigator.vendor || window.opera,
					os: HK.getMobileOperatingSystem() === "unknown" ? "Web" : HK.getMobileOperatingSystem(),
					platform: HK.isMobile() ? "mobile" : "desktop",
					store: "gritzo",
					userId: HK.loggedIn() || localStorage.getItem('isUserLoggedIn') || "N/A",
					url: window.location.href,
				};
				HK.sendErrorDetails(body);
			} catch (_error) {
				const body = {
					errorType: "INTERNAL SERVER ERROR POPUP",
					errorStackTrace: "Error While Tracking Errors " + new Error(_error).message
				};
				HK.sendErrorDetails(body);
			}
		}
	},
	sendErrorDetails: function (body) {
		const reqObj = {
			method: "POST",
			url: HK.config.APIS.errorTracking,
			data: body
		};
		HK.ajaxCall(reqObj).then((/* res */) => {
			console.log("ERROR TRACKED");
		}).catch((err) => {
			console.log("ERROR TRACKING: ", err);
		});
	},
	closeErrorPopup: function () {
		document.body.classList.remove('overflow-hidden');
		let _errorDOM = document.getElementById('hk--error-popup');
		if (_errorDOM != null) {
			_errorDOM.style.display = 'none';
		}
	},
	// go to sec with animation
	// in case of postion: sticky or absolute need to pass perticuler section's class(_targetClass)
	goToSec(_id, _targetClass) {
		let prodDetailSec = document.getElementById(_id);
		let scrollPos = prodDetailSec.offsetTop - 100;
		let dom = _targetClass ? document.getElementsByClassName(_targetClass)[0] : document.documentElement;
		let frame = function () {
			if (Math.abs(dom.scrollTop - scrollPos) < 250) {
				clearInterval(animate);
			} else {
				dom.scrollTop = (dom.scrollTop + 200);
			}
			// console.log(Math.abs(dom.scrollTop - scrollPos) < 100,dom.scrollTop - scrollPos,dom.scrollTop,scrollPos);
		};
		let animate = setInterval(frame, 100);
	},
	// Push to loggly
	_logError: function (msg) {
		let logglyInstance = window._LTracker;
		let _userId = this.loggedIn();
		if (typeof logglyInstance !== 'undefined')
			logglyInstance.push({ req: window.location.href, error: msg, time: new Date(), userId: _userId });
	},
	// Indian Ruppee format
	formatRuppee: function (amt) {
		return amt.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
	},
	// isMobile
	isMobile: function (props) {
		let check = false;
		if (typeof window === 'undefined') {
			check = props && props.isMobile ? true : false;
			// console.log("SERVER ISMOBILE = ", check)
		} else {
			(function (a) {
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(a))
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			// console.log("CLIENT ISMOBILE = ", check)
		}
		return check;
	},
	// Mobile Operating System
	getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}
		if (/android/i.test(userAgent)) {
			return "Android";
		}
		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}
		return "unknown";
	},
	// Cookies
	Cookies: {
		get: function (cname) {
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i].trim();
				if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
			}
			return false;
		},
		set: function (cname, cvalue, exdays, setDefaultExpiry) {
			let _domain = window.location.hostname.indexOf('gritzo.com') > -1 || window.location.hostname.indexOf('healthkartqa.com') > -1 ? window.location.hostname.match(/[^.]+\.[^.]+$/) : null;
			_domain = (_domain !== null && _domain.indexOf('qa') === -1 && _domain.indexOf('stag') === -1) ? '.' + _domain[0] : false;
			const d = new Date();
			if (setDefaultExpiry) {
				d.setDate(exdays);
			} else {
				d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			}
			// d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires=" + d.toGMTString();
			if (_domain)
				document.cookie = cname + "=" + cvalue + "; " + expires + ";domain=" + _domain + ";path=/";
			else
				document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
		},
		// delete: function (name) {
		// 	let _domain = window.location.hostname.indexOf('gritzo.com') > -1 ? window.location.hostname.match(/[^.]+\.[^.]+$/) : null;
		// 	_domain = (_domain !== null && _domain.indexOf('qa') === -1 && _domain.indexOf('stag') === -1) ? '.' + _domain[0] : false;
		// 	if (_domain)
		// 		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + _domain + ";path=/";
		// 	else
		// 		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		// },
		delete: function (name) {
			let _domain = window.location.hostname.indexOf('gritzo.com') > -1 || window.location.hostname.indexOf('healthkartqa.com') > -1 ? window.location.hostname.match(/[^.]+\.[^.]+$/) : null;
			_domain = (_domain !== null && _domain.indexOf('qa') === -1 && _domain.indexOf('stag') === -1) ? '.' + _domain[0] : false;
			if (_domain) {
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + _domain + ";path=/";
			}
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		}
	},
	// basic form validation
	formValidate: {
		phoneNumber: function (str) {
			let string = str.toString();
			if (string.length === 0) {
				return false;
			}
			if (string.length !== 10) {
				return false;
			}
			else if (!config.const.PHONE_NO_REGEX.test(string)) {
				return false;
			}
			return true;
		},
		email: function (str) {
			let string = str.toString();
			if (string.length === 0) {
				return false;
			}
			if (string.length === 0) {
				return false;
			}
			if (!config.const.EMAIL_REGEX.test(string)) {
				return false;
			}
			return true;
		},
		password: function (str) {
			let string = str.toString();
			if (string.length === 0) {
				return false;
			}
			if (!string.replace(/\s/g, '').length) {
				return false;
			}
			return true;
		},
		otp: function (otp, length = 6) {
			let string = otp.toString();
			return string.length === length;
		},
		numeric: function (str) {
			let string = str ? str.trim().toString() : '';
			if (string.length === 0) {
				return false;
			}
			if (string.length === 0) {
				return false;
			}
			if (!config.const.NUMERIC_REGEX.test(parseInt(string))) {
				return false;
			}
			return true;
		},
	},
	__checkIfPackPage: function (data) {
		if (data && data.pk_type) {
			return data && data.pk_type ? true : false;
		} else if (data && data.url) {
			if (data.url.indexOf('/pk/') > -1 || data.url.indexOf('/pack/') > -1) {
				return true;
			} else {
				return false;
			}

		} else {
			return false;
		}
	},
	// Make URL
	urlMaker: function (fragment, navkey, itracker) {

		itracker = itracker || false;
		let _start = '';
		if (navkey && navkey.indexOf('BR-') > -1)
			_start = '';
		if (navkey && navkey.indexOf('VRNT-') > -1)
			_start = '/sv';

		if (navkey && navkey.indexOf('PA-') > -1)
			_start = '/pk';

		let _url = _start + fragment;
		if (navkey && _url.indexOf('navKey') === -1) {
			_url = `${_start}${fragment}?navKey=${navkey}`;
		}
		_url += itracker ? '&' + this.itracker(...itracker) : '';
		return _url;
	},
	postAjaxCall: function () {
		HK.reportErrorToDashboard({
			stackTrace: new Error().stack,
		});
		HK.handleUserLogout();
	},
	handleUserLogout: function () {
		Analytics.Scope().logout();
		__removeSiteStorage();
	},

	// If need to pass userId in API url then set isUserIdRequired =true
	// Ajax call
	ajaxCall: function (args, isUserIdRequired = false) {
		var timeout = 120000;
		var data = args.data || {};
		let method = args.method.toLowerCase();
		let URL;
		if (args.newUrl) {
			URL = args.newUrl;
		} else {
			URL = config.baseURL + args.url;
			if (method === 'get') {
				URL += (URL.indexOf('?') > -1 ? '&' : '?') + 'plt=' + (this.isMobile() ? 2 : 1);
				URL += (URL.indexOf('?') > -1 ? '&' : '?') + 'st=' + (this.config.storeId);
				// URL += (URL.indexOf('?') > -1 ? '&' : '?') + 'timestamp=' + (new Date().getTime());
				if (HK.loggedIn() && isUserIdRequired) {
					URL += (URL.indexOf('?') > -1 ? '&' : '?') + 'userId=' + (HK.loggedIn());
				}
			}
		}
		var header = { ...args.header };
		const pageURI = window && window.location.pathname;
		if (pageURI) {
			header = { ...header, pageuri: pageURI };
		}
		if (this.getSearchParmas().fromPush) { header = { ...header, ...{ frompush: true } }; }
		let isV3 = false;
		let userID;
		let authToken;
		if (args.header !== undefined && args.header.userId !== undefined) {
			userID = args.header.userId;
			authToken = args.header.authToken;
			isV3 = true;
		} else {
			let _tmpUser = localStorage.getItem('isTMPUser');
			userID = this.loggedIn() || (_tmpUser ? localStorage.getItem('isUserLoggedIn') : false);
			authToken = this.Cookies.get('assumeAuth') || this.Cookies.get('authtoken');
			isV3 = (userID && authToken) || (userID && authToken && _tmpUser);
		}
		// if(args.url && args.url.indexOf('variant/review/v3/add/image') > -1){
		// 	header = {
		// 		...{
		// 			'HKAUTH': userID + '|' + authToken,
		// 			'content-type': 'multipart/form-data',
		// 		},
		// 		...header,
		// 	};
		// }
		// if(args.newUrl && args.newUrl.indexOf(`${process.env['NEXT_PUBLIC_HK_SECURE_PAY_BASE_URL']}/gateway/response/`) > -1){
		// 	header = {
		// 		...{
		// 			'Content-Type': 'application/x-www-form-urlencoded',
		// 		},
		// 		...header,
		// 	};
		// 	data = new URLSearchParams(data);
		// 	console.log("URLSearchParams", data);
		// }
		if (isV3 && !args.newUrl) {
			header = {
				...{
					'HKAUTH': userID + '|' + authToken,
					'Content-Type': 'application/json'
				},
				...header,
			};
			if (this.Cookies.get('isRetailAssumed') === 'true') {
				header = {
					...header, ...{
						'ASSUMEAUTH': this.Cookies.get('origId') + '|' + this.Cookies.get('authtoken'),
					}
				};
			}
		}
		if ((URL.indexOf('/payment') > -1 || URL.indexOf('/sv') > -1 || URL.indexOf('/pk') > -1) && (URL.indexOf('/v3/freeCheckout') > -1 || URL.indexOf('/v3/loyalty') > -1 || URL.indexOf('/nativePayment') > -1 || URL.indexOf('/v3/accept') > -1 || URL.indexOf('/v3/create') || URL.indexOf('/accept/native/updatePaymentMode') > -1 || URL.indexOf('/accept/native') > -1 || URL.indexOf('/accept/native/tokenize') > -1 || URL.indexOf('/accept/native/new') > -1 || URL.indexOf('/accept/native') > -1 || URL.indexOf('/prepare') > -1 || URL.indexOf('/cardOfferForCard') > -1 || URL.indexOf('/issuerOfferWithAdditionalCriteria') > -1)) {
			let utmSource = __getCookie("affiliateName");
			let utmMedium = __getCookie("affiliateMedium");
			let utmCampaign = __getCookie("affiliateCampaign");
			if (utmSource) {
				header = {
					...header, ...{
						utm_source: utmSource || ''
					}
				};
			}
			if (utmMedium) {
				header = {
					...header, ...{
						utm_medium: utmMedium || ''
					}
				};
			}
			if (utmCampaign) {
				header = {
					...header, ...{
						utm_campaign: utmCampaign || ''
					}
				};
			}
		}
		if (!args.newUrl) {
			header = {
				...header,
				st: this.config.storeId,
				plt: this.isMobile() ? 2 : 1,
				device: localStorage.getItem('deviceId')
			};
			let pincode = this.Cookies.get('hkPincode');
			if (pincode !== 'undefined') {
				header = {
					...header,
					'pincode': pincode
				};
			}
		}
		if (method === "post" && args.url.indexOf('/gateway/response') == -1) {
			data.storeId = this.config.storeId;
			if (args.modifyPlt) {
				data.platformId = 1;
			} else {
				data.platformId = this.isMobile() ? 2 : 1;
			}
		}
		URL = URL.replace('#', '%23');
		/* ajax calls */
		if (method === "get") {
			return axios.get(URL, { headers: header }, { withCredentials: true }, { timeout: timeout }).then(res => {
				return new Promise(function (resolve, reject) {
					resolve(res);
				});
			}).catch(err => {
				if (err && err.response && err.response.status === 401) {
					HK.postAjaxCall();
				}
				if (URL.indexOf('/user/login') > -1 && err.response.status === 429) {
					window.dispatchEvent(new CustomEvent("tooManyRequests", { detail: err.response.data }));
				}
				return err;
			});
		}
		if (method === "post") {
			return axios.post(URL, data, { headers: header }, { withCredentials: true }, { timeout: timeout }).then(res => {
				return new Promise(function (resolve, reject) {
					resolve(res);
				});
			}).catch(err => {
				if (err && err.response && err.response.status === 401) {
					HK.postAjaxCall();
				}
				if (URL.indexOf('/user/login') > -1 && err.response.status === 429) {
					window.dispatchEvent(new CustomEvent("tooManyRequests", { detail: err.response.data }));
				}
				return err;
			});
		}
		if (method === "put") {
			return axios.put(URL, data, { headers: header }, { withCredentials: true }, { timeout: timeout }).then(res => {
				return new Promise(function (resolve, reject) {
					resolve(res);
				});
			}).catch(err => {
				if (err && err.response && err.response.status === 401) {
					HK.postAjaxCall();
				}
				if (URL.indexOf('/user/login') > -1 && err.response.status === 429) {
					window.dispatchEvent(new CustomEvent("tooManyRequests", { detail: err.response.data }));
				}
			});
		}
		if (method === "delete") {
			return axios.delete(URL, { headers: header }, { withCredentials: true }, { timeout: timeout }).then(res => {
				return new Promise(function (resolve, reject) {
					resolve(res);
				});
			}).catch(err => {
				if (err && err.response && err.response.status === 401) {
					HK.postAjaxCall();
				}
				if (URL.indexOf('/user/login') > -1 && err.response.status === 429) {
					window.dispatchEvent(new CustomEvent("tooManyRequests", { detail: err.response.data }));
				}
			});
		}
		// if (method === "get") {
		// 	return axios.get(URL, { headers: header }, { withCredentials: true }, { timeout: timeout })
		// }
		// if (method === "post") {
		// 	return axios.post(URL, data, { headers: header }, { withCredentials: true }, { timeout: timeout })
		// }
	},
	/* href  manipulation */
	_removeHreflang: function (callback) {
		let allHrefLang = document.querySelectorAll('link[rel="alternate"]');
		if (allHrefLang) {
			for (let i = 0; i < allHrefLang.length; i++) {
				allHrefLang[i].remove();
			}
		}
		if (callback) {
			callback();
		}
	},
	_sethrefLang: function (args) {
		let _currentURL = window.location.href.replace('view=desktop', '').replace('view=mobile');
		if (_currentURL.indexOf('?itracker') > -1) {
			_currentURL = _currentURL.split('?itracker')[0];
		} if (_currentURL.indexOf('&itracker') > -1) {
			_currentURL = _currentURL.split('&itracker')[0];
		}
		_currentURL = _currentURL.substring(_currentURL.length - 1) === '?' ? _currentURL.replace('/?', '') : _currentURL;
		if (_currentURL && (_currentURL.indexOf('~') > -1 || _currentURL.indexOf('?navKey=SCT-') > -1) || _currentURL.indexOf('?navKey=CP-') > -1) {
			this._removeHreflang();
			return;
		}
		this._removeHreflang(() => {
			for (var i = 0; i <= args.lang.length - 1; i++) {
				let link = document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', args.lang[i]);
				if (args.lang[i] == 'en-us') {
					link.setAttribute('href', args['url']);
				} else {
					link.setAttribute('href', _currentURL);
				}
				document.head.appendChild(link);
			}
		});
	},
	//SEO Manipulation
	_setSEO: function (_args, canonical, /*isPublished */) {
		if (canonical && canonical.length > 0 && canonical.indexOf('CT')) {
			canonical = canonical.replace('CP', 'SCT');
		}
		let args = _args;
		const href_url = window.location.href;
		let _currentURL = href_url.replace('view=desktop', '').replace('view=mobile');
		let ampURL = '';
		if (href_url.indexOf('/level/') > -1) {
			let noCanonicalUrl = {
				urlFragment: "/",
				navKey: null
			};
			canonical = noCanonicalUrl;
		}
		if (_currentURL.indexOf('?itracker') > -1) {
			_currentURL = _currentURL.split('?itracker')[0];
		} if (_currentURL.indexOf('&itracker') > -1) {
			_currentURL = _currentURL.split('&itracker')[0];
		}
		_currentURL = _currentURL.substring(_currentURL.length - 1) === '?' ? _currentURL.replace('/?', '') : _currentURL;
		if (canonical !== null && canonical !== undefined) {
			_currentURL = config.AppURL + canonical.urlFragment + (canonical.navKey !== null ? '?navKey=' + canonical.navKey : '');
			ampURL = config.AppURL + '/amp' + canonical.urlFragment + (canonical.navKey !== null ? '?navKey=' + canonical.navKey : '');
		}
		if (canonical !== null && canonical !== undefined && canonical.path) {
			_currentURL = config.AppURL + canonical.path;
		}
		if (window.pageType === 'home') {
			ampURL = 'https://www.gritzo.com/amp';
		}
		if (_currentURL.indexOf('navKey') > -1) {
			let newURL = _currentURL.split('?navKey')[0];
			let navkey = this.getSearchParmas(_currentURL).navKey;
			if (navkey) {
				if (navkey.indexOf('navkey') > -1 || navkey.indexOf('?') > -1 || navkey.indexOf('/') > -1) {
					navkey = this.filterNavKey(navkey);
					_currentURL = newURL + (navkey ? '?navKey=' + navkey : '');
				}
			}
		}
		if (args && args.fullUrl) {
			_currentURL = args.fullUrl;
		}
		if (args && args.canonicalUnknown) {
			_currentURL = '';
		}
		if (this.getSearchParmas().selCatPre) {
			_currentURL = '';
		}
		document.title = args.title;
		document.querySelector('meta[property="og:title"]') && document.querySelector('meta[property="og:title"]').setAttribute('content', args.title);
		document.querySelector('meta[name="twitter:title"]') && document.querySelector('meta[name="twitter:title"]').setAttribute('content', args.title);
		document.querySelector('meta[property="og:description"]') && document.querySelector('meta[property="og:description"]').setAttribute('content', (args.metaDesc !== undefined ? args.metaDesc : ""));
		document.querySelector('meta[name="twitter:description"]') && document.querySelector('meta[name="twitter:description"]').setAttribute('content', (args.metaDesc !== undefined ? args.metaDesc : ""));
		document.querySelector('meta[name="description"]') && document.querySelector('meta[name="description"]').setAttribute('content', (args.metaDesc !== undefined ? args.metaDesc : ""));
		if (args.metaKeywrd !== undefined) {
			if (window.location.pathname === '/') {
				if (!document.querySelector('meta[name="keywords"]')) {
					let meta = document.createElement('meta');
					meta.setAttribute('name', 'keywords');
					document.head.appendChild(meta);
				}
				document.querySelector('meta[name="keywords"]').setAttribute('content', args.metaKeywrd);
			} else {
				document.querySelector('meta[name="keywords"]') && document.querySelector('meta[name="keywords"]').remove();
			}
		} else {
			document.querySelector('meta[name="keywords"]') && document.querySelector('meta[name="keywords"]').remove();
		}
		document.querySelector('meta[property="og:url"]') && document.querySelector('meta[property="og:url"]').setAttribute('content', _currentURL);
		document.querySelector('meta[property="og:image"]') && document.querySelector('meta[property="og:image"]').setAttribute('content', this.getImage("/logos/gritzo-logo.svg"));
		document.querySelector('meta[name="twitter:image:src"]') && document.querySelector('meta[name="twitter:image:src"]').setAttribute('content', this.getImage("/logos/gritzo-logo.svg"));
		// document.querySelector('meta[property="fb:app_id"]').setAttribute('content', config.const.FACEBOOK_APP_ID);
		document.querySelector('title').innerHTML = args.title;
		let elementexist = document.querySelector('link[rel="canonical"]');
		if (elementexist) {
			elementexist.setAttribute('href', _currentURL);
		}
		else {
			let link = document.createElement('link');
			link.setAttribute('rel', 'canonical');
			link.setAttribute('href', _currentURL);
			document.head.appendChild(link);
			elementexist = link;
		}
		// Nofollow meta for google bot
		let pageUrl = href_url.replace('view=desktop', '').replace('view=mobile');
		if (pageUrl.indexOf('~') > -1 || pageUrl.indexOf('?navKey=SCT-') > -1 || pageUrl.indexOf('?navKey=CP-') > -1) {
			if (!document.querySelector('meta[name="robots"]')) {
				let metaNoFollow = document.createElement("meta");
				metaNoFollow.setAttribute("name", "robots");
				metaNoFollow.setAttribute("content", "nofollow");
				document.head.appendChild(metaNoFollow);
			}
			let metaNoIndex = document.createElement("meta");
			metaNoIndex.setAttribute("name", "googlebot");
			metaNoIndex.setAttribute("content", "noindex");
			document.head.appendChild(metaNoIndex);
			if (elementexist) {
				elementexist.setAttribute('href', '');
			}
		}
		let isArticleNoFollow = this.isArticleNoFollow();
		if (isArticleNoFollow && elementexist) {
			elementexist.setAttribute('href', '');
		}
	},
	_setPaymentData(pageTitle) {
		document.title = pageTitle;
		document.querySelector('title').innerHTML = pageTitle;
	},
	//Update Datalayer from routes where router init not called
	_updateDataLayer: function (Dataobj = {}) {
		let _dataLayerObj = Dataobj;
		if (window.__RI !== undefined)
			_dataLayerObj = { ..._dataLayerObj, ...{ 'event': 'reactPageView' } };
		window.__RI = new Date();
		// console.log('_dataLayerObj _updateDataLayer', _dataLayerObj)
		// console.log('pageType _updateDataLayer', window.pageType)
		this._dataLayer(_dataLayerObj, window.pageType);
	},
	// DataLayer
	_dataLayer: function (args, pageType) {
		const _userData = __localStorageGet('userData') || {};
		let _datalayer = {
			'uH': 'guest',
			'uM': 'n.a.',
			'uG': 'n.a.',
			'oC': 'n.a.',
			'contact': 'n.a.',
			'firstName': 'n.a.',
			'birthDt': 'n.a.',
			'pageType': pageType || window.pageType,
			'pageUrl': window.location.href,
			'primaryCategory': '',
			'secondaryCategory': '',
			'tertiaryCategory': '',
			'allCats': [],
			'allNavKeys': [],
			'brand': '',
			'variantId': '',
			'productId': '',
			'cohort': '',
			'variantOfferPrice': '',
			'variantMrp': '',
			'variantDiscount': '',
			'variantName': '',
			'variantImageM': '',
			'variantImageS': '',
			'primaryMenu': '',
			'secondaryMenu': '',
			'tertiaryMenu': '',
			'navKey': '',
			'parentNavKey': '',
			'oos': '',
			'env': 'prod',
			'signup': '',
			'login': '',
			'guest': '',
			'canonicalUrl': 'https://www.gritzo.com',
			'errorCode': '',
			'browserCompat': 'true',
			'svTags': [],
			'cTags': [],
			'bTags': [],
			'isMobile': HK.isMobile(),
			'vendorName': '',
			'appliedOffer': '',
			'abTestMode': '',
			'ShoppingCartItemsWebEngage': [],
			'packDetails': {},
			'primaryAttributeName': "",
			'primaryAttributeValue': "",
			'secondaryAttributeName': "",
			'secondaryAttributeValue': "",
			'orderSuccessDetailsWebengage': {
				'transactionId': '',
				'totalValue': 0.0,
				'paymentMode': '',
				'shippingPrice': 0.0,
				'leafNode': "",
				'products': [
				],
				'brand': "",
				'category': "",
				'name': "",
				'variantId': "",
				'packId': ""
			},
			'newSession': true,
			'lpBrand': '',
			'lpPrimCat': '',
			'lpPrimMenu': '',
			'google_tag_parma_new': {
				'ecomm_prodid': '',
				'ecomm_pagetype': pageType || window.pageType,
				'ecomm_totalvalue': ''
			},
			'hkUserData': {
				..._userData
			}
		};
		let _localValue = JSON.parse(localStorage.getItem('_datalayer')) || [];
		let __datalayer = { ..._datalayer, ..._localValue, ...args, ..._userData };

		if (__datalayer.event !== undefined && args.event === undefined)
			delete __datalayer.event;
		localStorage.setItem('_datalayer', JSON.stringify(__datalayer));
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(__datalayer);
	},
	// Initial Data (user and page)
	_getInitData: function (pageType, navKey, _URL) {
		let _args = {
			page: {
				pgSeo: {
					title: "India's Best BodyBuilding Supplements..."
				}
			},
			user: []
		};
		let _userID = this.loggedIn();
		if (pageType === "search" || pageType === config.pageTypes.plp.freebieOffers || pageType === config.pageTypes.plp.bxgyOffers) {
			return new Promise(function (resolve, reject) {
				let _args = {
					page: {
						pgSeo: {
							title: "India's Best BodyBuilding Supplements... | gritzo.com"
						}
					},
					user: []
				};
				resolve(_args);
			});
		}
		// excluding pagedata call for retail refill & storeManagerFeedback page call
		if (pageType === config.pageTypes.campaign.retailRefill || pageType === config.pageTypes.campaign.storeManagerFeedback) {
			return new Promise(function (resolve, reject) {
				let _args = {
					page: {
						pgSeo: {
							title: pageType === config.pageTypes.campaign.storeManagerFeedback ? 'FeedBack' : 'Retail Refill'
						}
					},
					user: []
				};
				resolve(_args);
			});
		}
		let _url;
		if (config.persona.existingPageTypes[pageType] === "HM") {
			_url = config.APIS.home.firstFold;
			//_url = config.APIS.pageData + config.persona.existingPageTypes[pageType] /*+ (_userID ? '?userId=' + _userID : '')*/
		}
		else if (config.persona.existingPageTypes[pageType] === "DEALOFFER") {
			_url = config.APIS.pageData + "deal-offer";
		} else if (pageType === "clearance") {
			let userDeviceId = localStorage.getItem('deviceId');
			_url = _URL;
			// _url = config.APIS.sale.replace('NAV_KEY', window.location.pathname.split("sale/")[1]);
			/*if(_userID)
				{
					_url+='?userId='+_userID;
				}
				else
				{
					 _url+='?deviceId='+userDeviceId;
				}*/
		}
		else if (config.persona.existingPageTypes[pageType] === "BRAND") {
			_url = config.APIS.brandPageData;
		}
		else if (pageType === "coupon") {
			_url = _URL;
		}
		else if (pageType === "justLaunch") {
			_url = _URL;
		}
		else if (pageType === "bestSellerUnder999") {
			_url = _URL;
		}
		else if (pageType === 'storelocator' || pageType === 'storeDetail' || pageType === 'storeList') {
			_url = _URL;
		}
		else if (pageType !== "storeVariant" && pageType !== "pack") {
			_url = config.APIS.pageData + navKey;
			if (this.getSearchParmas().selCatPre) {
				_url += '?selCatPre=' + this.getSearchParmas().selCatPre;
				if (HK.getSearchParmas().brands) {
					let brand = HK.getSearchParmas().brands;
					if (HK.getSearchParmas().brands.indexOf('~') > -1) {
						let splitbrands = HK.getSearchParmas().brands.split('~');
						brand = splitbrands[0];
					}
					_url += '&brand=' + brand;
				}
				if (this.getSearchParmas().goalNm) {
					_url += '&goalNm=' + this.getSearchParmas().goalNm;
				}
			}
			_url += (_userID ? (_url && _url.indexOf('?')) > -1 ? '&' : '?' + 'userId=' + _userID : '');
		}
		let _page;
		if (pageType !== "storeVariant" && pageType !== "pack") {
			_page = this.ajaxCall({
				url: _url,
				method: 'get'
			});
		} else {
			_page = this.ajaxCall({
				url: _URL,
				method: "get"
			});
		}
		return new Promise(function (resolve, reject) {
			_page.then(function (res) {
				if (!res.data.results.exception) {
					if (pageType === "storeVariant" || pageType === "pack") {
						_args = res;
						if (pageType === "pack") {
							let obj = {
								pgSeo: {
									title: _args.data.results.packs.nm + " | gritzo"
								}
							};
							_args.data.results.page = obj;
						}
					} else if (pageType === "clearance") {
						_args.page = res.data.results;
						let obj = {
							pgSeo: {
								title: _args.pgSeo
							}
						};
						_args.data.results.page = obj;
					} else if (pageType === 'justLaunch') {
						_args.page = res.data.results.page;
					} else if (pageType === "storelocator" || pageType === 'storeDetail' || pageType === 'storeList') {
						_args.page = res.data.results;
					} else {
						_args.page = res.data.results.page;
					}
				}
				resolve(_args);
			}).catch(function () {
				resolve(_args);
			});
		});
	},
	toast: function (msg) {
		let x = document.getElementById('HK_TOAST');
		x.innerText = msg;
		x.className = 'show';
		setTimeout(function () { x.className = x.className.replace('show', ''); }, 3000);
	},
	pagetTypeDetector: function (url) {
		if (url.indexOf('navKey=CP-') > -1)
			return 'category';
		if (url.indexOf('navKey=SCT-') > -1 || url.indexOf('navKey=CL-') > -1)
			return 'menuLanding';
		if (url.indexOf('navKey=BR-') > -1)
			return 'brandCatalog';
		if (url.indexOf('txtQ=') > -1)
			return 'search';
		if (url.indexOf('/flash-sale') > -1)
			return 'FlashSale';
		return false;
	},
	getFieldName: function (reqName) {
		let resultName;
		if (reqName.nm) {
			resultName = reqName.nm;
		}
		else if (reqName.dis_nm) {
			resultName = reqName.dis_nm;
		}
		else if (reqName.minVal && reqName.maxVal) {
			var name = reqName.minVal + "  -  " + Math.round(reqName.maxVal * 100) / 100;
			// var name = reqName.minVal + "  -  " + reqName.maxVal;
			resultName = name;
		}
		else if (reqName.val) {
			resultName = reqName.val;
		}
		return resultName;
	},
	// update screenname without routerinit
	updateScreenName: function () {
		if (localStorage.getItem('prevScreenName') === null) {
			localStorage.setItem('currScreenName', window.pageType);
			localStorage.setItem('prevScreenName', window.pageType);
		}
		if (window.pageType !== localStorage.getItem('currScreenName')) {
			let prevPage = localStorage.getItem('currScreenName');
			localStorage.setItem('prevScreenName', prevPage);
			localStorage.setItem('currScreenName', window.pageType);
		} else {
			localStorage.setItem('prevScreenName', window.pageType);
			localStorage.setItem('currScreenName', window.pageType);
		}
	},
	// openInApp:function(){
	// 	let fallbackURLIos="https://itunes.apple.com/us/app/healthkart/id995933852?ls=1&mt=8&referrer=utm_source%3Dhealthkart%26utm_medium%3Dmob";
	// 	let fallbackURLAnd="https://play.google.com/store/apps/details?id=com.healthkart.healthkart&hl=en&referrer=utm_source%3Dhealthkart%26utm_medium%3Dmob";
	// 	let _currentURL=window.location.href;
	// 	let originLength=window.location.origin.length + 1;
	// 	let deeplinkURL=_currentURL.substr(originLength);
	// 	if(deeplinkURL.indexOf('&') > -1){
	// 		deeplinkURL=deeplinkURL.substr(0,deeplinkURL.indexOf('&'));
	// 	}
	// 	if(window.pageType === 'home'){
	// 		deeplinkURL='home';
	// 	}
	// 	if(HK.getMobileOperatingSystem() === "Android"){
	// 		let linkingUrl="healthkart://"+ deeplinkURL;
	// 		window.location=linkingUrl;
	// 		setTimeout(function(){
	// 	      window.location.href=fallbackURLAnd;
	// 	  },200);
	// 	}
	// 	else if(HK.getMobileOperatingSystem() === "iOS"){
	// 		let linkingUrl="com.ios.prod.Healthkart://";
	// 		window.location.href=linkingUrl+deeplinkURL;
	// 		setTimeout(function(){
	// 	        window.location.href=fallbackURLIos;
	// 	    },100);
	// 	}
	// },
	openAppStore: function (ios) {
		if ((HK.getMobileOperatingSystem() === "iOS") || ios) {
			window.open("https://muscleblazeapp.page.link/iOS", "_blank");
		}
		else {
			window.open("https://play.google.com/store/apps/details?id=com.muscleblaze", "_blank");
			// window.open("https://play.google.com/store/apps/details?id=com.healthkart.healthkart&hl=en&referrer=utm_source%3Dhealthkart%26utm_medium%3Dmob", "_blank");

		}
	},
	triggerAppOpen(url) {
		let redirectUrl = url || window.location.href;
		const utm_source = __getSearchParmas().utm_source || '';
		const utm_campaign = __getSearchParmas().utm_campaign || '';
		let pageType = window.pageType || '';
		let linkData = {
			campaign: utm_campaign || (pageType ? `MobileWeb_${pageType}` : 'MobileWeb'),
			source: utm_source || 'MobileWeb',
			medium: pageType ? `MobileWeb_${pageType}` : 'MobileWeb',
			channel: utm_source || 'MobileWeb',
			feature: 'MobileWeb',
			data: {
				'$canonical_url': redirectUrl
			}
		};
		if (__getSearchParmas().utm_source) {
			linkData.deeplink_no_attribution = true;
		}
		console.log('linkData=====', linkData);
		Analytics.branchIo.link(linkData).then(function (value) {
			console.log('value=======', value);
			if ((__getMobileOperatingSystem() === "iOS")) {
				console.log(value, 'IOS');
				window.location.assign(value);
			}
			else {
				console.log(value, 'Android and Other');
				window.open(
					value
				);
			}
		});
	},
	openInApp: function (url = '') {
		HK.triggerAppOpen(url);
	},
	// openInApp: function () {
	// 	// let linkData = {
	// 	// 	campaign: window.pageType,
	// 	// 	source: this.getSearchParmas().utm_source ? this.getSearchParmas().utm_source : 'MobileWeb',
	// 	// 	medium: 'MobileWeb',
	// 	// 	data: {
	// 	// 		'$canonical_url': window.location.href
	// 	// 	}
	// 	// };
	// 	// Analytics.branchIo.link(linkData).then(function (value) {
	// 	// 	// if(HK.getMobileOperatingSystem() === "iOS"){
	// 	// 	// 	if(document.getElementsByClassName('appbar').length){
	// 	// 	// 		document.getElementsByClassName('appbar')[0].href = value
	// 	// 	// 	}
	// 	// 	// }
	// 	// 	// else{
	// 	// 	window.open(
	// 	// 		'value'
	// 	// 	);
	// 	// 	// }
	// 	// }
	// 	if (window !== 'undefined') {
	// 		window.location.href = `https://muscleblazeapp.page.link/oMdp`
	// 	}
	// },
	deepLinkMeta: function (isProduct, id, remove) {
		if (remove) {
			let pID = document.querySelector("meta[name='branch:deeplink:productId']");
			if (pID !== null) {
				pID.remove();
				document.querySelector("meta[name='branch:deeplink:productView']").remove();
			}
			document.querySelector("meta[name='branch:deeplink:categoryId']") && document.querySelector("meta[name='branch:deeplink:categoryId']").remove();
			return "";
		}
		if (isProduct) {
			let metaPrId = document.createElement("meta");
			let meta = document.createElement("meta");
			if (document.querySelector("meta[name='branch:deeplink:productId']")) {
				metaPrId = document.querySelector("meta[name='branch:deeplink:productId']");
				meta = document.querySelector("meta[name='branch:deeplink:productView']");
			}
			metaPrId.setAttribute("name", "branch:deeplink:productId");
			metaPrId.setAttribute("content", id);
			meta.setAttribute("name", "branch:deeplink:productView");
			meta.setAttribute("content", true);
			document.head.appendChild(metaPrId);
			document.head.appendChild(meta);
		}
		else {
			let metaC = document.createElement("meta");
			if (document.querySelector("meta[name='branch:deeplink:categoryId']")) {
				metaC = document.querySelector("meta[name='branch:deeplink:categoryId']");
			}
			metaC.setAttribute("name", "branch:deeplink:categoryId");
			metaC.setAttribute("content", id);
			document.head.appendChild(metaC);
		}
	},
	ScreenOrientation: {
		type: {
			landscape: "landscape-primary",
			portrait: "portrait-primary"
		},
		lock: function (orientation) {
			if ("orientation" in window.screen) {
				if (orientation === this.type.landscape) {
					this.landscapeMode();
				}
				else {
					this.portraitMode();
				}
				window.screen.orientation.lock(orientation);
			}
		},
		landscapeMode: function () {
			// Kind of painful, but this is how it works for now
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		},
		portraitMode: function () {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	},
	checkForceLogin: function (token) {
		let reqObj = {
			async: false,
			method: 'GET',
			url: HK.config.APIS.userFlow.forceLoginUser + token
		};
		return new Promise(function (resolve, reject) {
			HK.ajaxCall(reqObj).then((res) => {
				if (!res.data.results.exception) {
					const result = res.data.results;
					const userData = {
						uG: result.gender || '',
						uM: result.email || '',
						uH: result.uid || '',
						contact: result.cntNum,
						firstName: result.nm != null && result.nm.length > 0 ? result.nm : "User",
						birthDt: result.birthDt || '',
						em_subsc: result.em_subsc || null,
						hkCash: result.tot_redeem_pts > 0 ? result.tot_redeem_pts : 0
					};
					if (res.data.results.tkn && res.data.results.uid) {
						HK.Cookies.set('authtoken', '"' + result.tkn + '"', 365);
						localStorage.setItem('isUserLoggedIn', result.uid);
						HK.setCookieLoggedIn(result.uid);
						localStorage.setItem('userData', JSON.stringify(userData));
						localStorage.removeItem('geoUserId');
						if (localStorage.getItem('isTMPUser')) {
							localStorage.removeItem('isTMPUser');
							HK.Cookies.delete('isTMPUser');
						}
						let eventData = {};
						Analytics.Scope().triggerClickStreamEvent(HK.config.eventNames.PAYMENT_RETRY_AUTOLOGIN, eventData);
						resolve(true);
					}
					const url = HK.removeQueryParam("frctkn", window.location.href);
					window.location.href = url;
				} else {
					//	console.log(":: ERROR FORCE LOGIN VALIDATE ::");
					resolve(false);
					const url = HK.removeQueryParam("frctkn", window.location.href);
					window.location.href = url;
				}
			}).catch(err => {
				reject(err);
				HK.errorPopup("Something went wrong, please try again.");
				HK.reportErrorToDashboard({
					stackTrace: new Error().stack,
					errorMessage: err
				});
			});
		});
	},
	setNoIndexMeta: function (pageType) {
		let noIndMeta = document.createElement('meta');
		let nmAttrib = document.createAttribute('name');
		let contAttrib = document.createAttribute('content');
		nmAttrib.value = "robots";
		contAttrib.value = "noindex";
		noIndMeta.setAttributeNode(nmAttrib);
		noIndMeta.setAttributeNode(contAttrib);
		document.head.appendChild(noIndMeta);
		let seoData = {};
		if (pageType === 'search') {
			const searchParam = this.getSearchParmas().txtQ;
			if (searchParam) {
				seoData = {
					title: `Search for ${searchParam} | gritzo`,
					metaDesc: `Shop ${searchParam} Online at gritzo. Get Free Delivery & COD on order above 500.`
				};
			}
		}
		this._setSEO(seoData);
		let canonicalLink = document.querySelector('link[rel="canonical"]');
		if (canonicalLink) {
			canonicalLink.remove();
		}
		let ampLink = document.querySelector('link[rel="amphtml"]');
		if (ampLink) {
			ampLink.remove();
		}
	},
	removeNoIndMeta: () => {
		let allMetaInd = document.querySelectorAll('meta[content="noindex"]');
		if (allMetaInd) {
			for (let i = 0; i < allMetaInd.length; i++) {
				allMetaInd[i].remove();
			}
		}
	},
	chatIcon: (isChatIcon) => {
		var body = document.body;
		if (isChatIcon) {
			body.classList.remove("no-chat-button");
		} else {
			body.classList.add("no-chat-button");
		}
	},
	footerAction: (isSet, className) => {
		var body = document.body;
		if (isSet) {
			body.classList.add(className);
		} else {
			body.classList.remove(className);
		}
	},
	filterNavKey: function (navkey) {
		let navKey = navkey;
		if (navKey) {
			if (navKey.indexOf('navKey') > -1) {
				navKey = navKey.indexOf('?navKey') ? navKey.split('?navKey')[0] : navKey.split('navKey')[0];
			}
			if (navKey.indexOf('/') > -1) {
				navKey = navKey.split('/')[0];
			}
			if (navKey.indexOf('?') > -1) {
				navKey = navKey.split('?')[0];
			}
		}
		return navKey;
	},
	updateQueryStringParameter: function (uri, key, value) {
		var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
		var separator = uri.indexOf('?') !== -1 ? "&" : "?";
		if (uri.match(re)) {
			return uri.replace(re, '$1' + key + "=" + value + '$2');
		}
		else {
			return uri + separator + key + "=" + value;
		}
	},
	tildUrlHandling: function () {
		let pageUrl = window.location.href.replace('view=desktop', '').replace('view=mobile');
		let isArticleNoFollowPage = this.isArticleNoFollow();
		let isNoFollow = pageUrl.indexOf('~') > -1 || this.getSearchParmas().selCatPre || isArticleNoFollowPage;
		// Nofollow meta for google bot
		if (isNoFollow) {
			if (!document.querySelector('meta[name="robots"]')) {
				let metaNoFollow = document.createElement("meta");
				metaNoFollow.setAttribute("name", "robots");
				metaNoFollow.setAttribute("content", "nofollow");
				document.head.appendChild(metaNoFollow);
			}
			if (!document.querySelector('meta[name="googlebot"]')) {
				let metaNoIndex = document.createElement("meta");
				metaNoIndex.setAttribute("name", "googlebot");
				metaNoIndex.setAttribute("content", "noindex");
				document.head.appendChild(metaNoIndex);
			}
			let elementexist = document.querySelector('link[rel="canonical"]');
			if (elementexist) {
				elementexist.setAttribute('href', '');
			}
		}
	},
	isArticleNoFollow: function () {
		let pathname = window.location.pathname;
		let regex1 = /\/articles\/content/;
		let isArticleNoFollowPage = regex1.test(pathname);
		return isArticleNoFollowPage;
	},
	customUrlEncoding: function (text) {
		if (!text) {
			return text;
		}
		text = text.replace(/\s/g, '-');
		return text.replace(/[^a-zA-Z0-9-]/g, '');
	},
	chatIcon: (chat, app) => {
		let isChatIcon = chat;
		let isAppBtn = app;
		var body = document.body;
		if (isChatIcon == undefined) {
			isChatIcon = true;
		}
		if (isAppBtn == undefined) {
			isAppBtn = true;
		}
		body.classList.remove("no-chat-button");
		body.classList.remove("no-open-appbar");
		if (HK.isMobile()) {
			if (isChatIcon && isChatIcon == true) {
				body.classList.remove("no-chat-button");
			} else {
				body.classList.add("no-chat-button");
			}
			if (isAppBtn && isAppBtn == true) {
				body.classList.remove("no-open-appbar");
			} else {
				body.classList.add("no-open-appbar");
			}
		} else {
			if (isChatIcon && isChatIcon == true) {
				body.classList.remove("no-chat-button");
			} else {
				body.classList.add("no-chat-button");
			}
		}
	},
	isReviewEnabledForVariant: function (vrntData) {
		let { attr, isRevEnbldForAttr } = vrntData;
		if (typeof isRevEnbldForAttr !== 'undefined' && isRevEnbldForAttr !== null) {
			return isRevEnbldForAttr;
		}
		if (!attr || attr.length === 0) {
			return true;
		}
		for (let i = 0; i < attr.length; i++) {
			let currentAttr = attr[i].values || {};
			for (let j = 0; j < currentAttr.length; j++) {
				if (currentAttr[j].reviewEnabled === true) {
					return true;
				}
			}
		}
		return false;
	},
	updateURLParameter: function (url, param, paramVal) {
		var newAdditionalURL = "";
		var tempArray = url.split("?");
		var baseURL = tempArray[0];
		var additionalURL = tempArray[1];
		var temp = "";
		if (additionalURL) {
			tempArray = additionalURL.split("&");
			for (var i = 0; i < tempArray.length; i++) {
				if (tempArray[i].split('=')[0] != param) {
					newAdditionalURL += temp + tempArray[i];
					temp = "&";
				}
			}
		}

		const rows_txt = temp + "" + param + "=" + paramVal;
		let newHref = baseURL + "?" + newAdditionalURL + rows_txt;
		return newHref;
	},

	formatEventName: function (...args) {
		let eventName = args.join('_');
		return eventName.toUpperCase().replace(/\s/g, '_');
	},

	triggerPageEvent: function (eventName, isGaEvent, isClickStreamEvent, gaEventName) {
		if (eventName) {
			let eventData = {
				url: window.location.href,
				screenName: window.pageType,
				pageName: window.pageType,
			};
			if (isGaEvent && isClickStreamEvent) {
				HK.updateGaEvent(gaEventName);
				HK.pushDataToClickstream(eventName, eventData);
			} else if (isGaEvent) {
				HK.updateGaEvent(gaEventName);
			} else if (isClickStreamEvent) {
				HK.pushDataToClickstream(eventName, eventData);
			}
		}
	},
	pushDataToClickstream: function (eventName, data) {
		let eventData = {
			url: window.location.href,
		};
		if (data) {
			eventData = {
				...eventData,
				...data
			};
		}
		Analytics.Scope().triggerClickStreamEvent(eventName, eventData);
	},
	triggerComponentClickEvent: function (eventData) {
		Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.COMPONENT_CLICK, eventData);
	},
	triggerWishlistEvent: function (eventData, eventType) {
		Analytics.Scope().triggerClickStreamEvent(eventType || HK.config.eventName.ADD_TO_WISHLIST, eventData);
	},
	triggerAddToCartEvent: function (eventData) {
		Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.ADD_TO_CART, eventData);
	},
	triggerGoToCartEvent: function (eventData) {
		Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.GO_tO_CART, eventData);
	},

	triggerComponentViewEvent: function (eventData) {
		const isEmpty = Object.keys(eventData).length === 0;
		if (!isEmpty) {
			if (eventData.eventName) {
				eventData.eventName = eventData.eventName.slice(0, 49) || '';
			}
			if (eventData.widgetName) {
				eventData.widgetName = eventData.widgetName.slice(0, 49) || '';
			}
			Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.COMPONENT_VIEW, eventData);
		}
	},
	triggerErrorEvent: function (eventData) {
		const isEmpty = Object.keys(eventData).length === 0;
		if (!isEmpty) {
			if (eventData.eventName) {
				eventData.eventName = eventData.eventName.slice(0, 49) || '';
			}
			if (eventData.widgetName) {
				eventData.widgetName = eventData.widgetName.slice(0, 49) || '';
			}
			Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.ERROR, eventData);
		}
	},
	triggerComponentFocusEvent: function (eventData) {
		const isEmpty = Object.keys(eventData).length === 0;
		if (!isEmpty) {
			if (eventData.eventName) {
				eventData.eventName = eventData.eventName.slice(0, 49) || '';
			}
			if (eventData.widgetName) {
				eventData.widgetName = eventData.widgetName.slice(0, 49) || '';
			}
			Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.COMPONENT_FOCUS, eventData);
		}
	},
	triggerComponentUnloadEvent: function (eventData) {
		const isEmpty = Object.keys(eventData).length === 0;
		if (!isEmpty) {
			if (eventData.eventName) {
				eventData.eventName = eventData.eventName.slice(0, 49) || '';
			}
			if (eventData.widgetName) {
				eventData.widgetName = eventData.widgetName.slice(0, 49) || '';
			}
			Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.PAGE_UNLOAD, eventData);
		}
	},
	triggerComponentEnterEvent: function (eventData) {
		const isEmpty = Object.keys(eventData).length === 0;
		if (!isEmpty) {
			if (eventData.eventName) {
				eventData.eventName = eventData.eventName.slice(0, 49) || '';
			}
			if (eventData.widgetName) {
				eventData.widgetName = eventData.widgetName.slice(0, 49) || '';
			}
			Analytics.Scope().triggerClickStreamEvent(HK.config.eventName.COMPONENT_PRESS_ENTER, eventData);
		}
	},
	unloadEvent: function (mountTime) {
		if (!mountTime) return null;
		let endDate = new Date();
		let seconds = Math.round((endDate.getTime() - mountTime.getTime()) / 1000);
		const clickStream = {
			widgetName: 'Page Unload',
			eventName: "PAGE_UNLOAD",
			timerDuration: seconds
		};
		this.triggerComponentUnloadEvent(clickStream);
	},
	splitStr: function (str = '', index = -1) {
		let result = [str];
		try {
			const strArr = str.split(/\s/);
			const part1 = strArr.slice(0, index);
			const part2 = strArr.slice(strArr.length + index, str.length);
			result = [part1.join(' '), part2.join(' ')];
		} catch (err) {
			console.log(err);
		}
		return result;
	},
	isLink: (url) => {
		url = url || '';
		try {
			return !url.includes('http://') && !url.includes('https://');
		} catch (err) {
			console.log(err);
		}
	},
	roundOff: function (num, decimal) {
		let places = 10 * decimal;
		return Math.round(num * places) / places;
	},
	textCapitalise: function (str) {
		try {
			if (!str || str.length === 0) return str;
			return str.toLowerCase().split(/\s/).map(fragment => fragment.charAt(0).toUpperCase() + fragment.substr(1)).join(' ');
		} catch (err) {
			console.log(err);
		}
		return str;
	},

	formatText: {
		rupees: (amount) => `₹${amount.toLocaleString()}`
	},

	noScroll(flag) {
		if (flag) {
			document.querySelector('body').classList.add('no-scrolling');
		} else {
			document.querySelector('body').classList.remove('no-scrolling');
		}
	},

	wordCloudReviewsUrl(text, pageNo, perPage, reviewData) {
		let url = HK.config.APIS.review.wordCloudReviews + '?svId=' + (reviewData.id || reviewData.sv_det.id) + '&text=' + text + '&pageNo=' + pageNo + '&perPage=' + perPage;
		/* handle Query param */
		const newUrl = HK.removeQueryParam('storeVariantId', window.location.href);
		const queryParam = HK.getSearchParmas(newUrl);
		let param = '';
		if (Object.keys(queryParam).length > 0) {
			for (let key in queryParam) {
				if (key === 'attrNm' || key === 'attrVal') {
					param = param + "&" + key + '=' + queryParam[key];
				}
			}
			url = url + param;
		}
		return url;
	},

	wordCloudApiCall(reqObj, reviewData, setReviewData) {
		this.ajaxCall(reqObj).then((res) => {
			if (res && res.data && !res.data.results.exception) {
				const reviewResp = {
					...reviewData.rvw_resp,
					sv_rvw: res.data.results.sv_rvw,
					ttl_rvws: res.data.results.ttl_rvws
				};
				setReviewData({
					...reviewData,
					rvw_resp: reviewResp
				});
			}
		});
	},

	getMBCashData() {
		if (localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).tot_redeem_pts) {
			return JSON.parse(localStorage.getItem('userData')).tot_redeem_pts.toLocaleString();
		}
		else return 0;
	},

	isPhonePeSwitch: function () {
		return typeof sessionStorage === 'object' && sessionStorage.getItem('utm_source') === this.config.PHONEPE;
	},

	sortOnKey: function (obj, key) {
		try {
			return obj.sort(function (a, b) {
				if (a[key] > b[key]) {
					return 1;
				}
				if (b[key] > a[key]) {
					return -1;
				}
				return 0;
			});
		} catch (err) {
			console.log(err);
		}
	},

	myOrders: {
		getItemPrice: function (varAs, netOprLiAmnt, offer_pr) {
			const price = varAs == 20 ? netOprLiAmnt : offer_pr;
			return price;
		},
		totalItemPrice: function (offer_pr, quantityNumber) {
			return offer_pr * quantityNumber;
		}
	},

	getPluralText(length, singularText, pluralText) {
		return length > 1 ? pluralText : singularText;
	},

	isExpressCheckout: function (/*url */) {
		let searchParams = this.getSearchParmas();
		return searchParams.cartId && searchParams.expressCheckout == 'true';
	},

	getUserData: function () {
		return JSON.parse(localStorage.getItem('userData'));
	},

	loader: function (isShow) {
		let x = document.getElementById('mb-loader');
		if (x) {
			if (isShow) {
				x.classList.add('show');
			} else {
				x.classList.remove('show');
			}
		}
	},

	scrollIntoView: function (id,) {
		const ele = document.getElementById(id);
		if (ele) {
			ele.scrollIntoView({ block: "start", behavior: 'smooth' });
		}
	},
	updateGaEventNme: function (eventName, data) {
		let eventData = {};
		if (eventName) {
			eventData.event = eventName;
		}
		if (data) {
			eventData = { ...eventData, ...data };
		}
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(eventData);
	},

	lbToKg: function (valInLbs) {
		return valInLbs / 2.2046;
	},

	round: function (num, pos = 1) {
		try {
			num = parseFloat(num);
			if (num % 1 === 0)
				return num;
			return num.toFixed(pos);
		} catch (err) {
			console.log(err);
		}
	},
	fixLBToKG: function (valInLbs) {
		let result = valInLbs / 2.2;
		const KG = ' kg';
		const G = ' g';
		let postFix = G;
		try {
			if (result < 1) {
				result = 1000 * result;
				result = parseFloat(result.toFixed(0));
				let lastDigit = result % 10;
				let increment = 10 - lastDigit;
				if (increment === 5) {
					// do nothing
				} else if (increment > 5) {
					result = result - lastDigit;
				} else {
					result = result + increment;
				}
				if (result === 1000) {
					result = 1;
					postFix = KG;
				}
			} else {
				result = parseFloat(result.toFixed(2));
				if (result % 1 === 0) {
					result = result.toFixed(0);
				}
				postFix = KG;
			}
		} catch (error) {
			console.log("ERROR WHILE CONVERTING LB-TO-KG ", error);
			return "";
		}
		return result + postFix;
	},
	lazyLoadImages: function (htmlStr) {
		try {
			let _html = document.createElement('div');
			_html.innerHTML = htmlStr;
			let _imgs = _html.getElementsByTagName('img');
			for (let i = 0; i < _imgs.length; i++) {
				_imgs[i].loading = 'lazy';
			}
			return _html.innerHTML;
		} catch (error) {
			console.log("Error converting HTML", error);
			return htmlStr;
		}
	},
	getAge: function (birthDate) {
		return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
	},
	getCartDataForClickEvent: function (variantStore) {
		let item_id_array = [];
		//let brand_array = [];
		let price_array = [];
		let offer_price_array = [];
		let quantity_array = [];
		let item_position_array = [];
		let item_pos = 1;

		variantStore.length > 0 && variantStore.forEach(item => item_id_array.push(item.navKey));
		variantStore.length > 0 && variantStore.forEach(item => price_array.push(item.totMrp));
		variantStore.length > 0 && variantStore.forEach(item => offer_price_array.push(item.totOffPr));
		variantStore.length > 0 && variantStore.forEach(item => quantity_array.push(item.qty));
		variantStore.length > 0 && variantStore.forEach(() => item_position_array.push(item_pos++));

		let cartDataString = item_id_array.toString() + "||" + price_array.toString() + "||" + offer_price_array.toString() + "||" + quantity_array.toString() + "||" + item_position_array.toString();

		return cartDataString;
	},

	getCartPackDataForClickEvent: function (packStore) {
		let pack_id_array = [];
		//let pack_brand_array = [];
		let pack_price_array = 0;
		let pack_offer_price_array = 0;
		let pack_quantity_array = 0;
		let pack_position_array = [];
		//let pack_pos = 1;
		packStore.length > 0 && packStore.forEach(item => pack_id_array.push(item.navKey));
		//packStore.length > 0 && packStore.forEach(item => pack_position_array.push(pack_pos++))
		packStore.length > 0 && packStore.forEach(item => pack_offer_price_array = pack_offer_price_array + parseInt(item.totOffPr));
		packStore.length > 0 && packStore.forEach(item => pack_quantity_array = pack_quantity_array + parseInt(item.qty));
		packStore.length > 0 && packStore.forEach(item => pack_price_array = pack_price_array + parseInt(item.totMrp));

		let packDataString = pack_id_array.toString() + "||" + pack_price_array.toString() + "||" + pack_offer_price_array.toString() + "||" + pack_quantity_array.toString() + "||" + pack_position_array.toString();

		return packDataString;
	},

	appendItemPosPackCart: function (pack_start_item_pos, temp_array) {
		temp_array[0] = pack_start_item_pos;
		for (let i = 1; i < temp_array.length; i++) {
			temp_array[i] = ++pack_start_item_pos;
		}
		return temp_array;
	},
	setCookieLoggedIn: function (userid, isTemp) {
		this.Cookies.set('isUserLoggedIn', '"' + userid + '"', 365);
		if (isTemp) {
			this.Cookies.set('isTMPUser', '"' + isTemp + '"', 365);
		}
	},
	mbWalletTriggerEvent: function (widgetName) {
		const clickStream = {
			widgetName: 'Top_Navigation',
			eventName: 'Top_Navigation',
			itemname: 'Mb_Wallet'
		};
		HK.triggerComponentClickEvent(clickStream);

		const viewStream = {
			...clickStream
		};
		viewStream.eventName = widgetName + "_VIEW";
		HK.triggerComponentViewEvent(viewStream);
	},

	changeAddressClick: function (_cartOrderSummaryReducer, screenname) {
		const { cartPacks, cartVar } = _cartOrderSummaryReducer;
		let data_array = [];
		if (cartVar && cartVar.length > 0) {
			cartVar.map((item) => {
				item.navKey && data_array.push(item.navKey);
			});
		}
		if (cartPacks && cartPacks.length > 0) {
			cartPacks.map((item) => {
				item.navKey && data_array.push(item.navKey);
			});
		}
		const eventData = {
			eventName: "CHANGE_ADDRESS_BUTTON_CLICK",
			widgetName: "Change address button click",
			itemName: data_array && data_array.length > 0 ? data_array.join() : "",
			screenName: screenname
		};
		HK.triggerComponentClickEvent(eventData);
	},

	getIntOrEmpty: function (val) {
		let result = '';
		try {
			let number = parseInt(val);
			result = Number.isInteger(number) ? number : '';
		} catch (err) {
			console.log(err);
		}
		return result;
	},
	getSimplEmiPrice: function (productPrice, totalInstallments) {
		let amountInPaise = productPrice * 100;
		let div = Math.floor(amountInPaise / totalInstallments);
		let rem = amountInPaise - (div * 3);
		let part = div + rem;
		let amountInRs = parseFloat(part / 100);
		return amountInRs;
	},
	stopScroll: function (isTrue) {
		if (isTrue) { //stops scroll
			document.getElementById('mainBodyId').style.overflow = 'hidden';
		} else { //enables scroll
			document.getElementById('mainBodyId').style.overflow = '';
		}
	},
	fromApp: function () {
		let temp = false;
		let isApp = HK.getSearchParmas().fromApp;
		temp = isApp && isApp.toString().toLowerCase() === "true" ? true : false;
		return temp;
	},
	_validAlphabet: function (str) {
		let string = str ? str.trim().toString() : '';
		if (string.length === 0) {
			return false;
		}
		if (string.length === 0) {
			return false;
		}
		if (!config.const.ALPHABET_REGEX.test(string)) {
			return false;
		}
		return true;
	}
};
export default HK;