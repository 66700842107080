import HK from 'factory/modules/HK';
import { __getCookie, __getSearchParmas, __isEmptyObj, __localStorageGet, __showChatBot } from './HK_service';
import config from './config';
import sdkConfig from './constants/sdkConfig';
const crypto = require('crypto');
const iv = Buffer.from('0000000000000000');
const brand = 'gritzo';
export const VerloopSDK = {
    createUserData: function () {
        let userData;
        let chatParamConfig = __getSearchParmas();
        if (chatParamConfig && chatParamConfig.userName && chatParamConfig.userID && chatParamConfig.authToken) {
            userData = chatParamConfig;
        } else if (HK.loggedIn()) {
            userData = __localStorageGet('userData');
        }
        return userData;
    },
    /* For decrypt config from url in case of APP web view */
    decrypt: function (data, key) {
        var decodeKey = crypto.createHash('sha1').update(key, 'utf-8').digest().slice(0, 16);
        var mykey = crypto.createDecipheriv('aes-128-cbc', decodeKey, iv);
        var mystr = mykey.update(data, 'hex', 'utf8');
        mystr += mykey.final('utf8');
        console.log('buffer', mystr);
        return mystr;
    },
    createLoginConfig: function (obj, type) {
        let bindingObj = {
            userName: '',
            userEmail: '',
            userPhone: '0000000000',
            userId: '',
            Hkauth: '',
            plt: HK.isMobile() ? "2" : "1",
            st: config.storeId.toString(),
            deviceId: '',
        };
        if (obj) {
            if (type == 1) {
                const AUTHTOKEN_KEY = sdkConfig.AUTHTOKEN_KEY;
                bindingObj = {
                    userName: obj.userName ? this.decrypt(obj.userName, AUTHTOKEN_KEY) : '',
                    userEmail: obj.email ? this.decrypt(obj.email, AUTHTOKEN_KEY) : '',
                    userPhone: '0000000000',//obj.mobileNo ? this.decrypt(obj.mobileNo, AUTHTOKEN_KEY) : ''
                    userId: obj.userID ? this.decrypt(obj.userID, AUTHTOKEN_KEY) : '',
                    Hkauth: obj.authToken ? this.decrypt(obj.authToken, AUTHTOKEN_KEY) : '',
                    plt: HK.isMobile() ? "2" : "1",
                    st: config.storeId.toString(),
                    deviceId: obj.deviceId ? this.decrypt(obj.deviceId, AUTHTOKEN_KEY) : '',
                };
            } else if (type == 2) {
                const _tmpUser = __localStorageGet('isTMPUser');
                const _isUserLoggedIn = __localStorageGet('isUserLoggedIn');
                const deviceId = __localStorageGet('deviceId');
                let Hkauth = __getCookie('assumeAuth') || __getCookie('authtoken');
                let tempAuth = Hkauth && Hkauth.split('"');
                if (tempAuth && tempAuth.length >= 2) {
                    Hkauth = Hkauth && Hkauth.split('"')[1];
                }
                bindingObj = {
                    userName: obj.firstName,
                    userEmail: obj.uM,
                    userPhone: '0000000000',//obj.contact,
                    userId: HK.loggedIn() || (_tmpUser ? _isUserLoggedIn : false),
                    Hkauth: Hkauth,
                    plt: HK.isMobile() ? "2" : "1",
                    st: config.storeId.toString(),
                    deviceId: deviceId,
                };
            }
        }

        return bindingObj;
    },

    /* Verloop SDK initialization*/
    sdkInit: function (callback) {
        __showChatBot(false);
        const userData = VerloopSDK.createUserData();
        if (userData && (userData.userID || userData.uH)) {
            if (window && window.Verloop) {
                const Verloop = window.Verloop;
                if (Verloop) {
                    Verloop(function () {
                        VerloopSDK.sdkListner(this, callback);
                    });
                }
            }
            else {
                ((w, d, s, u) => {
                    w.Verloop = function (c) { w.Verloop._.push(c); }; w.Verloop._ = []; w.Verloop.url = u;
                    var h = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = true;
                    j.src = 'https://gritzo.verloop.io/livechat/script.min.js';
                    j.id = "verloop_sk";
                    h.parentNode.insertBefore(j, h);
                })(window, document, 'script', 'https://gritzo.verloop.io/livechat');
                const Verloop = window.Verloop;
                Verloop(function () {
                    VerloopSDK.sdkListner(this, callback);
                });
            }
        } else {
            return;
        }
    },

    /* Verloop SDK Listner */
    sdkListner: ($this, callback) => {
        let userData = VerloopSDK.createUserData();
        let chatParamConfig = HK.getSearchParmas();
        let customField = {
            userId: '',
            Hkauth: '',
            deviceId: '',
            plt: HK.isMobile() ? "2" : "1",
            st: config.storeId.toString(),
            userName: '',
            userEmail: '',
            userPhone: '0000000000'
        };
        if (chatParamConfig && chatParamConfig.userName && chatParamConfig.userID && chatParamConfig.authToken) {
            customField = VerloopSDK.createLoginConfig(userData, 1);
        } else if (HK.loggedIn()) {
            customField = VerloopSDK.createLoginConfig(userData, 2);
        }
        $this.setRecipe("Lcnmhb9pZosRXrd4r");
        $this.setCustomField("userId", customField.userId, { scope: "user" });
        $this.setCustomField("Hkauth", customField.Hkauth, { scope: "user" });
        $this.setCustomField("deviceId", customField.deviceId, { scope: "user" });
        $this.setCustomField("plt", customField.plt, { scope: "user" });
        $this.setCustomField("st", customField.st, { scope: "user" });
        $this.setUserParams({
            name: customField.userName,
            email: customField.userEmail,
            phone: '0000000000'//customField.userPhone
        });
        typeof callback === 'function' && callback(true);
    },

    /* Verloop SDK Custom Method for Expand UI */
    expandWidgit: function (data, sourceParam) {
        const Verloop = window.Verloop;
        if (!Verloop) return;
        Verloop(function () {
            let userData = VerloopSDK.createUserData();
            let chatParamConfig = HK.getSearchParmas();
            let customField = {
                userId: '',
                Hkauth: '',
                deviceId: '',
                plt: HK.isMobile() ? "2" : "1",
                st: config.storeId.toString(),
                userName: '',
                userEmail: '',
                userPhone: '0000000000'
            };
            if (chatParamConfig && chatParamConfig.userName && chatParamConfig.userID && chatParamConfig.authToken) {
                customField = VerloopSDK.createLoginConfig(userData, 1);
            } else if (HK.loggedIn()) {
                customField = VerloopSDK.createLoginConfig(userData, 2);
            }
            const loyaltyLevelName = __localStorageGet("loyaltyLevelName") || "";
            const source = sourceParam || window.pageType || __localStorageGet("currScreenName") || "";
            this.setRecipe("Lcnmhb9pZosRXrd4r");
            this.setCustomField("userId", customField.userId, { scope: "user" });
            this.setCustomField("Hkauth", customField.Hkauth, { scope: "user" });
            this.setCustomField("deviceId", customField.deviceId, { scope: "user" });
            this.setCustomField("plt", customField.plt, { scope: "user" });
            this.setCustomField("st", customField.st, { scope: "user" });
            this.setCustomField("brand", brand, { scope: "user" });
            this.setCustomField("loyalty_status", loyaltyLevelName, { scope: "room" });
            this.setCustomField("source", source, { scope: "room" });
            if (data && !__isEmptyObj(data)) {
                const edd = (data.estDeliveryDate) || (data.oprLineItems && data.oprLineItems.length > 0 && data.oprLineItems[0].estDeliveryDate) || "";
                this.setCustomField("order_id", data.gId || "", { scope: "room" });
                this.setCustomField("order_amount", data.orderAmount || "", { scope: "room" });
                this.setCustomField("order_date", data.orderDt || "", { scope: "room" });
                this.setCustomField("EDD", edd, { scope: "room" });
            }
            this.setUserParams({
                name: customField.userName,
                email: customField.userEmail,
                phone: '0000000000', //customField.userPhone
            });
            this.openWidget();
        });
    },

    /* Verloop SDK Custom Method for Open in URL */
    openInUrl: function (callback) {
        VerloopSDK.sdkInit(callback);
    },
    logout: function () {
        const Verloop = window.Verloop;
        if (!Verloop) return;
        Verloop(function () {
            __showChatBot(false);
            this.closeWidget();
            this.logout();
        });
    },
};
export default VerloopSDK;